



































import {Component} from "vue-property-decorator";
import Vue from "vue";
import {RouteNext} from "@/types";
import {vxm} from "@/store";
import {Route, Location} from "vue-router";
import {TaxonomyMedia} from "@/service";

@Component
export default class TaxonomyPictures extends Vue {

  private IMGURLBASE = vxm.main.mediaUrlBase;

  private gotoMarking(mediaId: number) {
    this.$router.push({
      name: "TaxonomyPortrait",
      params: {
        list: this.$route.params.list,
        node: this.$route.params.node,
        media: mediaId.toString()
      }
    });
  }

  private get images(): TaxonomyMedia[] {
    return vxm.mediaModule.data.images;
  }

  private beforeRouteEnter(to: Route, from: Route, next: RouteNext) {
    vxm.mediaModule.listNodeImages({
      listId: +to.params.list,
      nodeId: +to.params.node,
    });
    next();
  }

  private beforeRouteUpdate(to: Route, from: Route, next: RouteNext) {
    vxm.mediaModule.listNodeImages({
      listId: +to.params.list,
      nodeId: +to.params.node,
    });

    next();
  }

  // private items = [
  //   {
  //     src: 'http://localhost:1323/media/big.jpg',
  //   },
  //   {
  //     src: 'http://localhost:1323/media/medium.jpg',
  //   },
  //   {
  //     src: 'http://localhost:1323/media/small.jpg',
  //   },
  // ];
}
